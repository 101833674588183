@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("/inc/image/ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("/inc/font/fonts/slick.eot");
  src: url("/inc/font/fonts/slick.eot?#iefix") format("embedded-opentype"), url("/inc/font/fonts/slick.woff") format("woff"), url("/inc/font/fonts/slick.ttf") format("truetype"), url("/inc/font/fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

#top .headerLogo h1 {
  font-size: 0; }

#top .mainImg {
  overflow: hidden;
  position: relative; }
  #top .mainImg .slider li {
    width: 100%;
    height: 730px; }
    @media screen and (max-width: 800px) {
      #top .mainImg .slider li {
        height: 361px; } }
    #top .mainImg .slider li.slide01 {
      background: url(/inc/image/top/slider/pic_slider01.jpg) no-repeat;
      background-size: cover;
      background-position: center; }
      @media screen and (max-width: 568px) {
        #top .mainImg .slider li.slide01 {
          background-size: cover;
          background-position: right -180px top; } }
    #top .mainImg .slider li.slide02 {
      background: url(/inc/image/top/slider/pic_slider02.jpg) no-repeat;
      background-size: cover;
      background-position: center; }
      @media screen and (max-width: 568px) {
        #top .mainImg .slider li.slide02 {
          background-size: cover;
          background-position: top center; } }
    #top .mainImg .slider li.slide03 {
      background: url(/inc/image/top/slider/pic_slider03.jpg) no-repeat;
      background-size: cover;
      background-position: center; }
      @media screen and (max-width: 568px) {
        #top .mainImg .slider li.slide03 {
          background-size: cover;
          background-position: center top; } }
    #top .mainImg .slider li.slide04 {
      background: url(/inc/image/top/slider/pic_slider04.jpg) no-repeat;
      background-size: cover;
      background-position: center; }
      @media screen and (max-width: 568px) {
        #top .mainImg .slider li.slide04 {
          background-size: cover;
          background-position: center top; } }
  #top .mainImg img.mainTxt {
    display: block;
    width: 819px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%); }
    @media screen and (max-width: 800px) {
      #top .mainImg img.mainTxt {
        width: 65%; } }
    @media screen and (max-width: 568px) {
      #top .mainImg img.mainTxt {
        width: 85%; } }

#top #strength > section {
  margin-bottom: 60px; }

#top #strength h2 span.maincopy {
  width: 65%; }
  @media screen and (max-width: 1024px) {
    #top #strength h2 span.maincopy {
      width: 55%; } }
  @media screen and (max-width: 800px) {
    #top #strength h2 span.maincopy {
      width: 100%; } }

#top #strength h2 span.subcopy {
  width: 35%; }
  @media screen and (max-width: 1024px) {
    #top #strength h2 span.subcopy {
      width: 45%; } }
  @media screen and (max-width: 800px) {
    #top #strength h2 span.subcopy {
      width: 100%; } }

#top #strength .alternate01 {
  position: relative;
  max-width: 1367px;
  margin: 0 auto; }
  @media screen and (max-width: 800px) {
    #top #strength .alternate01 {
      width: 100%;
      padding: 0 20px; } }
  #top #strength .alternate01 > img {
    display: inline-block;
    max-width: 1070px; }
    @media screen and (max-width: 1024px) {
      #top #strength .alternate01 > img {
        width: 100%; } }
    @media screen and (max-width: 800px) {
      #top #strength .alternate01 > img {
        width: 100%; } }
  #top #strength .alternate01 p {
    position: absolute;
    bottom: -135px;
    right: 25px; }
    @media screen and (max-width: 800px) {
      #top #strength .alternate01 p {
        bottom: -275px;
        right: 10px;
        z-index: 99;
        width: 80%; } }
    @media screen and (max-width: 568px) {
      #top #strength .alternate01 p {
        bottom: -145px; } }
    #top #strength .alternate01 p img {
      width: 100%; }

#top #strength .alternate02 {
  position: relative;
  max-width: 1367px;
  margin: 0 auto; }
  @media screen and (max-width: 800px) {
    #top #strength .alternate02 {
      width: 100%;
      padding: 0 20px; } }
  #top #strength .alternate02.grid {
    margin: 230px auto 0 auto; }
    @media screen and (max-width: 800px) {
      #top #strength .alternate02.grid {
        margin-top: 0; } }
  #top #strength .alternate02 .image {
    text-align: center; }
    @media screen and (max-width: 800px) {
      #top #strength .alternate02 .image {
        padding: 0;
        margin-top: 20px; } }
    #top #strength .alternate02 .image img {
      width: auto; }
  #top #strength .alternate02 .txtBox {
    position: relative;
    top: -100px;
    padding-right: 100px; }
    @media screen and (max-width: 800px) {
      #top #strength .alternate02 .txtBox {
        top: 0;
        padding: 0; } }

#top #strength #service {
  position: relative;
  max-width: 1367px;
  margin: 0 auto;
  padding-bottom: 0; }
  @media screen and (max-width: 800px) {
    #top #strength #service {
      width: 100%; } }
  #top #strength #service .service_box {
    background: #F0FBFF;
    position: absolute;
    right: 0;
    top: -245px;
    padding: 50px 100px 200px;
    width: 70%;
    z-index: -1; }
    @media screen and (max-width: 800px) {
      #top #strength #service .service_box {
        padding: 40px 0 10px 20px;
        position: relative;
        top: 0;
        left: 0;
        width: 80%; } }
    #top #strength #service .service_box section {
      padding-left: 145px; }
      @media screen and (max-width: 800px) {
        #top #strength #service .service_box section {
          padding-left: 0; } }
      #top #strength #service .service_box section h2 {
        margin-bottom: 0; }
        #top #strength #service .service_box section h2 span {
          width: 100%; }
      @media screen and (max-width: 800px) {
        #top #strength #service .service_box section p {
          white-space: nowrap; } }
  #top #strength #service .service_list {
    max-width: 1166px;
    margin: 0 auto -150px;
    position: relative;
    top: -150px; }
    @media screen and (max-width: 1024px) {
      #top #strength #service .service_list {
        width: 960px;
        top: 0; } }
    @media screen and (max-width: 800px) {
      #top #strength #service .service_list {
        width: 100%;
        top: 0;
        padding: 0 20px;
        margin: 0 auto; } }
    #top #strength #service .service_list .over-box:last-child {
      margin-bottom: 0; }

@media screen and (max-width: 800px) {
  #top #reason > section {
    overflow: hidden; } }

#top #reason .reason_list_wrap {
  margin: 60px 0 120px; }
  #top #reason .reason_list_wrap .reason_list li {
    padding-bottom: 20px; }
    @media screen and (max-width: 800px) {
      #top #reason .reason_list_wrap .reason_list li {
        padding: 0 10px 20px; } }
    @media screen and (max-width: 568px) {
      #top #reason .reason_list_wrap .reason_list li {
        padding: 0 30px 60px; } }
    #top #reason .reason_list_wrap .reason_list li figure {
      text-align: center;
      margin-bottom: 10px; }
    #top #reason .reason_list_wrap .reason_list li .title {
      display: flex;
      align-items: center;
      font-size: 2.1rem;
      font-weight: 600;
      margin: 0; }
      @media screen and (max-width: 800px) {
        #top #reason .reason_list_wrap .reason_list li .title {
          justify-content: center; } }
      @media screen and (max-width: 568px) {
        #top #reason .reason_list_wrap .reason_list li .title {
          justify-content: flex-start; } }
      #top #reason .reason_list_wrap .reason_list li .title .number {
        color: #0092E5;
        font-size: 5.2rem;
        margin-right: 22px; }
  #top #reason .reason_list_wrap .readmore .btn {
    width: 654px; }
    @media screen and (max-width: 800px) {
      #top #reason .reason_list_wrap .readmore .btn {
        width: 100%; } }

#top #reason .consult {
  text-align: left; }
  #top #reason .consult .underRect {
    position: relative;
    width: 100%;
    margin-bottom: 50px;
    overflow: hidden; }
    @media screen and (max-width: 800px) {
      #top #reason .consult .underRect {
        overflow: initial; } }
    #top #reason .consult .underRect:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 60%;
      background-color: #F0FBFF;
      display: block;
      z-index: -2; }
    #top #reason .consult .underRect .detail {
      position: relative;
      z-index: 10;
      width: 415px; }
      @media screen and (max-width: 800px) {
        #top #reason .consult .underRect .detail {
          width: 100%; } }
    #top #reason .consult .underRect .centerImg {
      width: 790px;
      position: relative;
      margin: 0 auto;
      top: -85px;
      z-index: 1; }
      @media screen and (max-width: 800px) {
        #top #reason .consult .underRect .centerImg {
          width: 100%;
          top: 0; } }
      #top #reason .consult .underRect .centerImg .only-sp {
        width: 100%; }
      #top #reason .consult .underRect .centerImg .labelBtn {
        position: absolute; }
        @media screen and (max-width: 800px) {
          #top #reason .consult .underRect .centerImg .labelBtn {
            position: relative;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0; } }
        #top #reason .consult .underRect .centerImg .labelBtn a.btn {
          display: block; }
          @media screen and (max-width: 800px) {
            #top #reason .consult .underRect .centerImg .labelBtn a.btn {
              margin: 0;
              margin-left: auto;
              margin-right: auto;
              padding: 5px 0; } }
    #top #reason .consult .underRect.right:after {
      right: -25%;
      top: 7%; }
      @media screen and (max-width: 800px) {
        #top #reason .consult .underRect.right:after {
          right: -15%; } }
    #top #reason .consult .underRect.right .centerImg .labelBtn {
      bottom: 15px;
      right: -115px; }
      @media screen and (max-width: 1024px) {
        #top #reason .consult .underRect.right .centerImg .labelBtn {
          right: -50px; } }
      @media screen and (max-width: 800px) {
        #top #reason .consult .underRect.right .centerImg .labelBtn {
          right: 0;
          margin-top: 20px; } }
      #top #reason .consult .underRect.right .centerImg .labelBtn a.btn:nth-child(2) {
        margin-left: 40px;
        margin-top: 10px; }
        @media screen and (max-width: 800px) {
          #top #reason .consult .underRect.right .centerImg .labelBtn a.btn:nth-child(2) {
            margin-left: auto;
            margin-right: auto; } }
    #top #reason .consult .underRect.left .detail {
      float: right;
      width: 330px; }
      @media screen and (max-width: 800px) {
        #top #reason .consult .underRect.left .detail {
          float: none; } }
    #top #reason .consult .underRect.left:after {
      left: -25%;
      top: 4%; }
      @media screen and (max-width: 800px) {
        #top #reason .consult .underRect.left:after {
          left: -15%; } }
    #top #reason .consult .underRect.left .centerImg .labelBtn {
      bottom: 15px;
      left: -115px; }
      @media screen and (max-width: 1024px) {
        #top #reason .consult .underRect.left .centerImg .labelBtn {
          left: -50px; } }
      @media screen and (max-width: 800px) {
        #top #reason .consult .underRect.left .centerImg .labelBtn {
          left: 0;
          margin-top: 20px; } }
      #top #reason .consult .underRect.left .centerImg .labelBtn a.btn:nth-child(2) {
        margin-left: -40px;
        margin-top: 10px; }
        @media screen and (max-width: 800px) {
          #top #reason .consult .underRect.left .centerImg .labelBtn a.btn:nth-child(2) {
            margin-left: auto;
            margin-right: auto; } }

#top #client {
  padding: 0 20px;
  margin-bottom: 250px; }
  @media screen and (max-width: 800px) {
    #top #client {
      margin-bottom: 60px; } }
  #top #client .clientWrap {
    max-width: 1367px;
    margin: 0 auto; }
    @media screen and (max-width: 800px) {
      #top #client .clientWrap {
        width: 100%;
        padding: 40px 20px;
        border: solid 1px #C7E1F0; } }
    #top #client .clientWrap .clientList {
      position: relative;
      left: -50px; }
      @media screen and (max-width: 1024px) {
        #top #client .clientWrap .clientList {
          left: 0; } }
      @media screen and (max-width: 800px) {
        #top #client .clientWrap .clientList {
          order: 1;
          left: 0; } }
      #top #client .clientWrap .clientList .card {
        padding: 0; }
        @media screen and (max-width: 800px) {
          #top #client .clientWrap .clientList .card {
            padding: 0 10px; } }
        @media screen and (max-width: 568px) {
          #top #client .clientWrap .clientList .card {
            margin-top: 25px;
            padding: 0; }
            #top #client .clientWrap .clientList .card:first-child {
              margin-top: 0; } }
        #top #client .clientWrap .clientList .card > a {
          display: block;
          transition: opacity 0.4s ease; }
          #top #client .clientWrap .clientList .card > a:hover {
            opacity: 0.7; }
        #top #client .clientWrap .clientList .card .card-img {
          height: 214px; }
          #top #client .clientWrap .clientList .card .card-img img {
            width: auto;
            height: 100%;
            display: block;
            margin: 0 auto; }
          #top #client .clientWrap .clientList .card .card-img .thumb {
            width: 100%;
            height: 100%;
            background-size: cover; }
            @media screen and (max-width: 568px) {
              #top #client .clientWrap .clientList .card .card-img .thumb {
                margin: 0 auto; } }
          #top #client .clientWrap .clientList .card .card-img .noimg {
            width: 100%;
            height: 100%;
            background: url(/inc/image/client/img_noImage.jpg) no-repeat;
            background-position: center center;
            background-size: cover; }
            @media screen and (max-width: 568px) {
              #top #client .clientWrap .clientList .card .card-img .noimg {
                margin: 0 auto; } }
        #top #client .clientWrap .clientList .card .card-body {
          padding: 12px 20px; }
          #top #client .clientWrap .clientList .card .card-body p {
            margin: 0; }
          #top #client .clientWrap .clientList .card .card-body .label {
            padding: 5px;
            width: 30%;
            font-size: 1.2rem; }
          #top #client .clientWrap .clientList .card .card-body .date {
            font-size: 1.2rem;
            width: 70%;
            padding-left: 10px; }
          #top #client .clientWrap .clientList .card .card-body .companyName {
            line-height: 1;
            margin: 10px 0;
            font-size: 1.8rem;
            color: #4AAFED; }
          #top #client .clientWrap .clientList .card .card-body .detail {
            font-size: 1.4rem; }
    #top #client .clientWrap.behindBox {
      position: relative; }
      #top #client .clientWrap.behindBox:after {
        content: "";
        position: absolute;
        display: block;
        width: 73%;
        height: 515px;
        border: solid 1px #C7E1F0;
        top: -25%;
        right: 0;
        z-index: -1; }
        @media screen and (max-width: 800px) {
          #top #client .clientWrap.behindBox:after {
            border: none;
            width: 100%;
            height: auto; } }

#top #work {
  padding: 0 20px;
  margin-bottom: 110px; }
  #top #work .workWrap {
    max-width: 1367px;
    margin: 0 auto; }
    @media screen and (max-width: 800px) {
      #top #work .workWrap {
        width: 100%;
        padding: 40px 20px;
        border: solid 1px #C7E1F0; } }
    #top #work .workWrap .titleBox {
      padding-left: 90px;
      padding-right: 0; }
      @media screen and (max-width: 800px) {
        #top #work .workWrap .titleBox {
          padding: 0 30px 60px; } }
    #top #work .workWrap .workList {
      position: relative;
      left: 40px; }
      @media screen and (max-width: 800px) {
        #top #work .workWrap .workList {
          order: 1;
          left: 0; } }
      #top #work .workWrap .workList .card {
        padding: 0 10px; }
        @media screen and (max-width: 800px) {
          #top #work .workWrap .workList .card {
            margin-top: 25px; }
            #top #work .workWrap .workList .card:first-child {
              margin-top: 0; } }
        #top #work .workWrap .workList .card > a {
          display: block;
          transition: opacity 0.4s ease; }
          #top #work .workWrap .workList .card > a:hover {
            opacity: 0.7; }
        #top #work .workWrap .workList .card .card-body {
          padding: 16px 22px;
          border: solid 1px #C7E1F0; }
          #top #work .workWrap .workList .card .card-body p {
            margin: 0; }
          #top #work .workWrap .workList .card .card-body .cat {
            padding: 0 5px;
            margin-right: 6px;
            vertical-align: middle; }
            #top #work .workWrap .workList .card .card-body .cat.green {
              background-color: #BBF284; }
          #top #work .workWrap .workList .card .card-body .jirei {
            font-size: 1.2rem; }
          #top #work .workWrap .workList .card .card-body .date {
            font-size: 1.2rem;
            float: right; }
          #top #work .workWrap .workList .card .card-body .workTitle {
            line-height: 1;
            margin: 10px 0;
            font-size: 1.8rem;
            color: #4AAFED; }
          #top #work .workWrap .workList .card .card-body .detail {
            font-size: 1.4rem; }
    #top #work .workWrap.behindBox {
      position: relative; }
      #top #work .workWrap.behindBox:after {
        content: "";
        position: absolute;
        display: block;
        width: 73%;
        height: 389px;
        border: solid 1px #C7E1F0;
        top: -25%;
        left: 0;
        z-index: -1; }
        @media screen and (max-width: 800px) {
          #top #work .workWrap.behindBox:after {
            border: none;
            width: 100%;
            height: auto; } }

#top .cv-sodankai {
  margin-bottom: 0; }

#top #news {
  padding-bottom: 40px; }
  #top #news .news .news-list:after {
    display: none; }
  @media screen and (max-width: 1024px) {
    #top #news .news .news-list span.date {
      float: none; } }
  #top #news .news .news-list[target="_blank"] h4:after {
    content: "\f24d";
    font-family: 'FontAwesome'; }
  #top #news .news .news-list[href$=".pdf"] h4:after {
    content: "\f1c1";
    font-family: 'FontAwesome'; }

#top #link {
  margin-bottom: 130px; }
  @media screen and (max-width: 800px) {
    #top #link {
      margin-bottom: -70px; } }
  #top #link .linkWrap {
    max-width: 1166px;
    margin: 0 auto; }
    @media screen and (max-width: 800px) {
      #top #link .linkWrap {
        width: 100%; } }
    #top #link .linkWrap > [class*='col-'] {
      padding-bottom: 0; }
      @media screen and (max-width: 800px) {
        #top #link .linkWrap > [class*='col-'] {
          padding: 0 30px 30px; }
          #top #link .linkWrap > [class*='col-']:last-child {
            padding-bottom: 0; } }
      #top #link .linkWrap > [class*='col-'] a {
        display: block;
        transition: opacity 0.4s ease;
        position: relative; }
        #top #link .linkWrap > [class*='col-'] a:hover {
          opacity: 0.7; }
        #top #link .linkWrap > [class*='col-'] a img {
          width: 100%; }
      #top #link .linkWrap > [class*='col-'] .title {
        color: #fff;
        font-size: 5rem;
        font-weight: 600;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%); }
        @media screen and (max-width: 568px) {
          #top #link .linkWrap > [class*='col-'] .title {
            font-size: 4rem; } }
